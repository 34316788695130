import './skip-link-focus-fix';
import AOS from 'aos';
import 'script-loader!lity';
import 'script-loader!slick-carousel';
import 'script-loader!jquery.marquee';


(function($) {
	AOS.init({ once: true, duration: 500 });

	$(document).ready(function() {
		menuToggle();
		accordionShortcode();

		$(window).on('scroll', function(){
			headerEffects();
			animatedLineDrawing();
			featuredWorkGalleryAccordion();
			featuredWorkGalleryFilters();
			// videoCaseStudiesFilters();
			videoCaseStudiesPopupVideo();
		});

		$(window).trigger('scroll');

		$('.marquee').marquee();
	});

	// Homepage header effects
	function headerEffects() {
		var header         = $('#masthead'),
		    scrollPosition = $(window).scrollTop();

		if(scrollPosition > 100)
			header.addClass('site-header--scrolled');
		else
			header.removeClass('site-header--scrolled');
	}

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__toggle').on('click', function () {
			var accordion = $(this).parent('.accordion-shortcode'),
				content = $(this).prev('.accordion-shortcode__content');

			content.toggle();
			accordion.toggleClass('accordion-shortcode--open');
		});
	}

	// Line drawing
	function animatedLineDrawing() {
		$('.animated-path').each(function() {
			var path = $(this)[0];

			var viewportHeight = $(window).height(),
			scrollTop = $(window).scrollTop(),
			elementOffsetTop = $(this).offset().top,
			elementHeight = $(this).height(),
			percentageSeen;

			if (elementOffsetTop > (scrollTop + viewportHeight)) {
				percentageSeen = 0;
			} else if ((elementOffsetTop + elementHeight) < scrollTop) {
				percentageSeen = 1;
			} else {
				var distance = (scrollTop + viewportHeight) - elementOffsetTop;
				var percentage = distance / ((viewportHeight + elementHeight) / 100);
				percentageSeen = percentage / 100 + 0.1;
			}
			
			var pathLength = path.getTotalLength();

			path.style.strokeDasharray = pathLength + ' ' + pathLength;

			path.style.strokeDashoffset = pathLength;

			path.getBoundingClientRect();

			var drawLength = pathLength * percentageSeen;
			
			path.style.strokeDashoffset = pathLength - drawLength;
				
			if (percentageSeen >= 0.99) {
				path.style.strokeDasharray = "none";
				
			} else {
				path.style.strokeDasharray = pathLength + ' ' + pathLength;
			}
		});
	}

	// Featured work gallery block accordion content
	function featuredWorkGalleryAccordion() {
		$('.featured-gallery__accordion-toggle').on('click', function(e) {
			e.preventDefault();

			var accordionContent = $(this).closest('.featured-gallery__container').children('.featured-gallery__accordion-content');

			$(this).toggleClass('featured-gallery__accordion-toggle--open');

			accordionContent.toggle();
		});
	}

	// Featured work gallery block filtering dropdown
	function featuredWorkGalleryFilters() {
		$('.featured-gallery__filter-toggle').on('click', function(e){
			e.preventDefault();

			$(this).toggleClass('featured-gallery__filter-toggle--open');
			$(this).next('.featured-gallery__filter-dropdown').toggleClass('featured-gallery__filter-dropdown--open');
		});
	}

	// Video case studies block filtering dropdown
	// function videoCaseStudiesFilters() {
	// 	$('.video-casestudies__filter-toggle').on('click', function(e){
	// 		e.preventDefault();

	// 		$(this).toggleClass('video-casestudies__filter-toggle--open');
	// 		$(this).next('.video-casestudies__filter-dropdown').slideToggle();
	// 	});
	// }

	// Video case studies popup video toggle
	function videoCaseStudiesPopupVideo() {
		$('.video-casestudies__popup-videolink').on('click', function(e){
			e.preventDefault();

			var videoContainer = $(this).next('.video-casestudies__popup-video')
			
			videoContainer.show();
		});
	}

})(jQuery);